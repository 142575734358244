<template>
  <validation-provider
    ref="datetime"
    #default="validationContext"
    :name="name"
    :rules="rules"
    :detect-input="false"
  >
    <b-form-group
      :label-for="id"
    >
      <template #label>
        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          {{ name }}
        </span>
      </template>
      <flat-pickr
        :id="id"
        v-model="dateTimeProxy"
        class="form-control datetime"
        :config="configProxy"
        :placeholder="placeholder"
        :state="getValidationState(validationContext)"
        :disabled="disabled"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@/@core/comp-functions/forms/form-validation'

export default {
  name: 'DateTime',
  components: {
    flatPickr,
  },
  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    rules: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    config: { type: Object, default: () => {} },
    datetime: { type: [Array, String, Date], default: '' },
    isEditFormProp: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      multipleDatesString: '',
    }
  },
  computed: {
    dateTimeProxy: {
      get() {
        return this.config.mode === 'multiple' ? this.multipleDatesString : this.datetime
      },
      set(value) {
        this.multipleDatesString = value
        const parsedDate = this.config.mode === 'multiple' ? this.convertMultipleDatesToArray(value) : value
        this.$emit('update:datetime', parsedDate)
        this.validateDate(parsedDate)
      },
    },
    isEditForm() {
      return !!this.$route.params.id || this.isEditFormProp
    },
    isDatePassed() {
      return this.$moment().isAfter(this.datetime)
    },
    configWithoutValidation() {
      return {
        ...this.config, minDate: '', maxDate: '', minuteIncrement: 1,
      }
    },
    configProxy() {
      return this.isDatePassed && this.isEditForm ? this.configWithoutValidation : { ...this.config, minuteIncrement: 1 }
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
  mounted() {
    this.validateDate()
  },
  methods: {
    convertMultipleDatesToArray(value) {
      return value.split(', ')
    },
    validateDate() {
      if (this.dateTimeProxy) { this.$refs.datetime.validate(this.dateTimeProxy) }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
input.datetime[disabled] {
  background-color:$input-disabled-bg !important
}

.dark-layout input.datetime[disabled] {
  background-color:$theme-dark-input-disabled-bg !important;
  border: 1px solid $theme-dark-input-disabled-border-color !important;
}

</style>
