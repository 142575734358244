<template>
  <b-card header="Export Prayers">
    <b-row>
      <b-col md="6">
        <date-time
          id="start-date"
          :datetime.sync="exportCriteria.from"
          name="Start Date"
          :config="{ dateFormat: 'Y-m-d'}"
        />
      </b-col>

      <b-col md="6">
        <date-time
          id="end-date"
          :datetime.sync="exportCriteria.to"
          name="End Date"
          :config="{ dateFormat: 'Y-m-d'}"
        />
      </b-col>
      <b-col cols="12">
        <b-button
          :disabled="!(exportCriteria.from && exportCriteria.to)"
          size="md"
          variant="primary"
          @click="exportPrayers"
        >
          Export
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { saveAs } from 'file-saver'
import DateTime from '@/common/components/common/FormInputs/DateTime.vue'

export default {
  name: 'ExportPrayers',
  components: { DateTime },
  data() {
    return {
      exportCriteria: {
        from: null,
        to: null,
      },
    }
  },
  methods: {
    exportPrayers() {
      this.$portalUsers.get(`/internalops/entity/${this.$store.getters['mainEntity/getEntityId']}/prayer/export`, {
        params: this.exportCriteria,
        responseType: 'blob',
      }).then(res => {
        const blob = new Blob([res.data])
        saveAs(blob, `salawt_${this.exportCriteria.from}_${this.exportCriteria.to}.xlsx`)
      })
    },
  },
}
</script>
<style lang="">

</style>
